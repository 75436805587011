'use client'; // Error components must be Client Components

import Link from 'next/link';
import React, { useEffect } from 'react';

// TODO: error page

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error,]);

  return (
    <div className='error-page'>
      <h1>Error</h1>
      <h2>Something went wrong!</h2>
      <Link href='/'>Return to site</Link>
    </div>
  );
}
